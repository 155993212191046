'use client';
import { Box, Button, ButtonProps, Stack, Typography } from '@mui/material';
import { MaterialIcon, MaterialIconType } from '../materialIcon';

/**
 * Renders a button with title and optional description that opens a link on a new tab.
 *
 * @param {string} title - The title of the link button.
 * @param {string} description - The optional description of the link button.
 * @param {string} link - The link URL to open.
 * @return {JSX.Element} The rendered link button.
 */
export function LinkButton({
  title,
  description,
  link,
  icon,
  ...buttonProps
}: {
  title: string;
  description?: string;
  link: string;
  icon: MaterialIconType;
} & ButtonProps) {
  return (
    <Button
      data-testid="linkButton"
      sx={{
        gap: { xs: 2, md: 4 },
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        textAlign: 'left',
        border: '1px solid',
        borderColor: 'primary.main',
        borderRadius: 4,
        padding: 2,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: '#F3ECFF',
        },
        '&:focus': {
          backgroundColor: '#F3ECFF',
        },
        transition: '0.2s ease-in-out',
      }}
      onClick={() => window.open(link, '_blank')}
      {...buttonProps}
    >
      <Stack direction="column" justifyContent="center" alignItems="flex-start">
        <Typography variant="h6" color="secondary.main" display="flex" alignItems="center" gap={1}>
          <MaterialIcon icon={icon} color="inherit" />
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </Stack>
      <Box>
        <MaterialIcon icon="open_in_new" color="primary" />
      </Box>
    </Button>
  );
}
