'use client';
import {
  Badge,
  Button,
  Collapse,
  Divider,
  IconButton,
  Table as MUITable,
  Pagination,
  Stack,
  TableBody,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import { Doodles } from '../../assets';
import { COLORS } from '../../constants';
// import { theme } from '../../theme';
import { dateToTimestamp } from '@repo/utils';
import { MaterialIcon, type MaterialIconType } from '../materialIcon';
import { filterField, Filters } from './filter';

export type SortType = 'date' | 'number' | 'alphabetic';

export type CellType = {
  label: string;
  showLabelMobile?: boolean;
  align?: TableCellProps['align'];
  sort?: SortType;
};

export type TableHeadersType = CellType[];

export type RowDataType = {
  name?: string;
  rawValue?: string;
  value: string | number | JSX.Element;
  align?: TableCellProps['align'];
};

export type TableDataType = {
  rowData: RowDataType[];
  rowRedirect?: string;
  rowAction?: () => void;
  dropdownContent?: JSX.Element;
};

export type TableActions = {
  onClick: () => void;
  label: string;
  icon: MaterialIconType;
  active?: boolean;
  badge?: number;
};

export type TableProps = {
  title?: string;
  titleData?: string;
  iconTitle?: MaterialIconType;
  subtitle?: string;
  actions?: TableActions[];
  headers: TableHeadersType;
  showHeadersMobile?: boolean;
  filters?: filterField[];
  tableData: TableDataType[];
  rows: number;
  flex?: boolean;
  dropdown?: boolean;
  clickableRows?: boolean;
  hideActions?: boolean;
  hidePagination?: boolean;
  downloadComponent?: JSX.Element;
};

/**
 * A component that renders a table with filters and pagination.
 *
 * The table will render a set of headers, and a set of rows. Each row will
 * contain a set of cells, with the value of each cell being the result of
 * `rowData[index].value`. If the value is an object, the object will be
 * rendered directly in the cell.
 *
 * The component will also render a set of filters above the table, and a
 * pagination component below the table. The filters will be rendered as a
 * set of text fields, with the label of each filter being the value of the
 * `label` property of the corresponding `filterField` object.
 *
 * The pagination component will render a set of buttons that allow the user
 * to navigate through the table. The buttons will be labeled with the page
 * number that they correspond to.
 *
 * The table will be rendered with a flexbox layout, with the headers and rows
 * being rendered as flex items. The flex items will be arranged in a row, with
 * the headers being rendered above the rows.
 *
 * The table will be rendered with a set of styles that make it look like a
 * material-ui table.
 *
 * @prop {string} title - The title of the table.
 *
 * @prop {string} titleData - Extra data for the title of the table.
 *
 * @prop {MaterialIconType} iconTitle - The icon title of the table.
 *
 * @prop {string} subtitle - The subtitle of the table.
 *
 * @prop {filterField[]} filters - An array of objects that define the filters
 * that will be rendered above the table.
 *
 * * `[date_from]` and `[date_to]` are used to filter date ranges
 *
 * * `|` will be interpreted as OR
 *
 * @prop {CellType[]} headers - An array of objects that define the headers of
 * the table.
 *
 * @prop {boolean} showHeadersMobile - A boolean that indicates whether the headers should be shown on mobile.
 *
 * @prop {TableDataType[]} tableData - An array of objects that define the
 * data that will be rendered in the table.
 *
 * @prop {number} rows - The number of rows that will be rendered in the table.
 *
 * @prop {boolean} flex - A boolean that indicates whether the rows variant
 * should be set to mobileFlex instead of the base table-row display.
 *
 * @prop {boolean} dropdown - A boolean that indicates whether the rows
 * should be a dropdown.
 *
 * @prop {boolean} clickableRows - A boolean that indicates whether the rows
 * should be clickable.
 *
 * @prop {boolean} hideActions - A boolean that indicates whether the
 * actions should be hidden.
 *
 * @prop {boolean} hidePagination - A boolean that indicates whether the
 * pagination component should be hidden.
 *
 * @prop {string} downloadAction - Action to download table CSV.
 *
 * @prop {downloadComponent} downloadComponent - Component to download table CSV.
 *
 * @returns {JSX.Element} - The rendered table component.
 */
export const Table = ({
  title,
  titleData,
  iconTitle,
  subtitle,
  filters,
  headers,
  showHeadersMobile,
  tableData,
  rows,
  flex,
  dropdown,
  clickableRows,
  hideActions,
  hidePagination,
  downloadComponent,
}: TableProps) => {
  const router = useRouter();
  const [tablePage, setTablePage] = useState(0);
  const [showFilters, setShowFilters] = useState(false);
  const searchParams = useSearchParams();

  const [filteredData, setFilteredData] = useState<TableDataType[]>(tableData);
  const [activeSort, setActiveSort] = useState<number>(-1);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [filterSize, setFilterSize] = useState<number>(0);

  useEffect(() => {
    let filteredSearchParams = new URLSearchParams(Array.from(searchParams.entries()));
    filteredSearchParams.delete('sortIndex');
    filteredSearchParams.delete('sortDirection');
    setFilterSize(filteredSearchParams.size);
    if (searchParams) {
      let filtered = [...tableData];
      if (filteredSearchParams.size > 0)
        filteredSearchParams.forEach((param, key) => {
          if (key.includes('[date_from]')) {
            filtered = filtered.filter(({ rowData }) =>
              rowData.some(
                ({ name, rawValue }) =>
                  name === key.replace('[date_from]', '') &&
                  new Date(`${rawValue?.split(' ')[0]}`) >= new Date(`${param}`),
              ),
            );
          } else if (key.includes('[date_to]')) {
            filtered = filtered.filter(({ rowData }) =>
              rowData.some(
                ({ rawValue, name }) =>
                  name === key.replace('[date_to]', '') &&
                  new Date(`${rawValue?.split(' ')[0]}`) <= new Date(`${param}`),
              ),
            );
          } else {
            filtered = filtered.filter(({ rowData }) =>
              rowData.some(({ rawValue, name, value }) => {
                const validValue = `${rawValue || value}`;
                return (
                  name === key &&
                  param?.split('|').some((_value) => validValue.toLowerCase().includes(_value.toLowerCase()))
                );
              }),
            );
          }
        });
      if (searchParams.get('sortIndex') && searchParams.get('sortDirection')) {
        const sortDirection = searchParams.get('sortDirection') === 'asc' ? 'asc' : 'desc';
        const sortIndex = parseInt(searchParams.get('sortIndex') || '0');
        const sortType = headers[sortIndex]?.sort;
        setActiveSort(sortIndex);
        setSortDirection(sortDirection);
        const decodeValue = (value: string) => (sortType === 'date' ? dateToTimestamp(value) : parseFloat(value));
        filtered.sort((a, b) => {
          const aValue = sortDirection === 'asc' ? a.rowData[sortIndex]?.rawValue : b.rowData[sortIndex]?.rawValue;
          const bValue = sortDirection === 'asc' ? b.rowData[sortIndex]?.rawValue : a.rowData[sortIndex]?.rawValue;
          if (sortType === 'alphabetic') {
            return aValue?.toLocaleLowerCase()?.localeCompare(bValue?.toLocaleLowerCase() || '') || 0;
          } else {
            return decodeValue(aValue || '0') - decodeValue(bValue || '0');
          }
        });
      }
      setFilteredData(filtered);
    }
  }, [searchParams, filters, tableData, showFilters]);

  const sortList = (i: number) => {
    const current = new URLSearchParams(Array.from(searchParams.entries()));
    current.get('sortDirection') === 'asc' && current.get('sortIndex') === `${i}`
      ? current.set('sortDirection', 'desc')
      : current.set('sortDirection', 'asc');
    current.set('sortIndex', `${i}`);
    if (current.size || searchParams.toString() !== current.toString()) {
      window.history.replaceState(null, '', `?${current.toString()}`);
    }
  };

  useEffect(() => {
    if (filteredData?.length <= rows) {
      setTablePage(0);
    }
  }, [filteredData]);

  return (
    <Grid container wrap="nowrap" sx={{ width: '100%', flexDirection: 'column', gap: 2, height: '100%' }}>
      {title || downloadComponent || filters?.length ? (
        <Grid
          container
          sx={{
            flexDirection: 'row',
            gap: 2,
            alignItems: 'center',
            justifyContent: 'space-between',
            px: { xs: 2, md: 0 },
            pt: { xs: 2, md: 0 },
          }}
        >
          {title && (
            <Grid
              sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center', justifyContent: 'flex-start' }}
            >
              <Typography variant="h1withIcon">
                {iconTitle && (
                  <MaterialIcon icon={iconTitle} sx={{ height: '100%', lineHeight: 1 }} color="secondary" />
                )}
                <span>{title}</span>
              </Typography>
              {titleData && (
                <Typography variant="body1" color="textDisabled" sx={{ display: { xs: 'none', md: 'block' } }}>
                  {titleData}
                </Typography>
              )}
            </Grid>
          )}
          <Grid
            container
            sx={{ flexDirection: 'row', gap: 2, alignItems: 'center', display: hideActions ? 'none' : 'flex' }}
          >
            {downloadComponent && tableData.length ? downloadComponent : null}
            {filters?.length ? (
              <Tooltip title={'Filtros'}>
                <IconButton onClick={() => setShowFilters(!showFilters)} sx={{ mt: -2, mb: -2, mr: -1 }}>
                  <Badge color="error" badgeContent={filterSize} invisible={!filterSize}>
                    <MaterialIcon
                      icon={'filter_list'}
                      sx={{ color: showFilters ? COLORS.primary.purple.main : COLORS.text.light3 }}
                    />
                  </Badge>
                </IconButton>
              </Tooltip>
            ) : null}
          </Grid>
        </Grid>
      ) : null}
      {subtitle && (
        <Typography sx={{ px: { xs: 2, md: 0 }, pb: { xs: 2, md: 0 }, width: '90%' }} mt={-2} variant="h2">
          <span>{subtitle}</span>
        </Typography>
      )}
      {filters && (
        <Collapse in={showFilters} sx={{ my: -2, px: { xs: 2, md: 0 } }}>
          <Filters fields={filters} />
        </Collapse>
      )}
      {filteredData?.length ? (
        <>
          <MUITable id="table-wrapper" data-testid="table" stickyHeader>
            <TableHead
              data-testid="table-head"
              sx={{ display: { xs: showHeadersMobile ? 'table-header-group' : 'none', md: 'table-header-group' } }}
            >
              <TableRow>
                {headers.length ? (
                  headers.map(({ label, align, sort }, i) => (
                    <TableCell key={label} align={align}>
                      {sort ? (
                        <TableSortLabel
                          active={activeSort === i}
                          direction={activeSort === i ? sortDirection : 'asc'}
                          sx={{ fontWeight: 400 }}
                          onClick={() => sortList(i)}
                        >
                          {label}
                        </TableSortLabel>
                      ) : (
                        <Typography display="flex" variant="body2" color="textSecondary" alignItems="center" gap={0.5}>
                          {label}
                        </Typography>
                      )}
                    </TableCell>
                  ))
                ) : (
                  <TableCell />
                )}
              </TableRow>
            </TableHead>
            <TableBody data-testid="table-body">
              {tableData.length ? (
                filteredData
                  .slice(tablePage * rows, tablePage * rows + rows)
                  .map(({ rowData, rowRedirect, rowAction, dropdownContent }, index) =>
                    dropdown ? (
                      <DropdownRow key={index} dropdownContent={dropdownContent}>
                        {rowData.slice(0, headers.length - 1).map(({ value, align }, index) => (
                          <TableCell
                            key={index}
                            align={align as TableCellProps['align']}
                            sx={{ flexWrap: { xs: 'wrap', md: 'unset' } }}
                          >
                            {typeof value === 'object' ? (
                              value
                            ) : (
                              <Typography
                                variant="body2"
                                sx={{ color: { xs: 'text.primary', md: COLORS.text.light3 } }}
                              >
                                {value}
                              </Typography>
                            )}
                          </TableCell>
                        ))}
                      </DropdownRow>
                    ) : (
                      <TableRow
                        variant={flex ? 'mobileFlex' : ''}
                        key={index}
                        hover={clickableRows}
                        sx={{ cursor: clickableRows ? 'pointer' : 'default' }}
                        onClick={() => (rowAction ? rowAction() : rowRedirect ? router.push(rowRedirect) : undefined)}
                      >
                        {rowData.map(({ value, align }, index) => (
                          <TableCell key={index} align={align as TableCellProps['align']}>
                            <>
                              {headers[index]?.showLabelMobile && headers[index]?.label && (
                                <Typography
                                  variant="body3"
                                  color="textSecondary"
                                  sx={{ display: { xs: 'block', md: 'none' } }}
                                >
                                  <span>{headers[index]?.label}:</span>
                                </Typography>
                              )}
                              {typeof value === 'object' ? (
                                value
                              ) : (
                                <Typography
                                  variant="body2"
                                  sx={{ color: { xs: 'text.primary', md: COLORS.text.light3 } }}
                                >
                                  <span>{value}</span>
                                </Typography>
                              )}
                            </>
                          </TableCell>
                        ))}
                      </TableRow>
                    ),
                  )
              ) : (
                <TableRow />
              )}
            </TableBody>
          </MUITable>
          {!hidePagination && Math.ceil(filteredData.length / rows) > 1 && (
            <Pagination
              count={Math.ceil(filteredData.length / rows)}
              page={tablePage + 1}
              size="large"
              boundaryCount={3}
              shape="rounded"
              onChange={(e, page) => setTablePage(page - 1)}
            />
          )}
        </>
      ) : (
        <Grid
          container
          justifyContent={'center'}
          alignItems={'center'}
          direction={'column'}
          gap={2}
          p={4}
          sx={{ width: '100%', flex: 1 }}
        >
          <Doodles type="end" />
          <Typography variant="body3">Ups, no encontramos resultados.</Typography>
        </Grid>
      )}
    </Grid>
  );
};

const DropdownRow = ({ dropdownContent, children }: { dropdownContent?: JSX.Element; children: React.ReactNode }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow variant="mobileFlex">
        {children}
        <TableCell sx={{ display: { xs: 'table-cell', md: 'none !important' } }}>
          <Collapse in={open} timeout="auto" unmountOnExit sx={{ width: '-webkit-fill-available' }}>
            <Grid
              sx={{
                // p: 2,
                // mb: { xs: 2, md: 0 },
                // mt: { xs: -3, md: 0 },
                // borderRadius: { xs: 2, md: 0 },
                // borderTopLeftRadius: { xs: 2, md: 0 },
                // borderTopRightRadius: { xs: 2, md: 0 },
                // border: '1px solid #E9DCFF',
                borderTop: 'none',
                // backgroundColor: COLORS.background[0],
              }}
            >
              {dropdownContent}
            </Grid>
          </Collapse>
        </TableCell>
        <TableCell>
          <IconButton
            sx={{ display: { xs: 'none', md: 'flex' } }}
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            <MaterialIcon
              icon="keyboard_arrow_down"
              color="secondary"
              sx={{
                transform: open ? 'rotate(-180deg)' : 'rotate(0)',
                transition: '0.2s ease',
              }}
            />
          </IconButton>
          <Stack width="100%" gap={1} sx={{ display: { xs: 'flex', md: 'none' }, pt: { xs: 1, md: 0 } }}>
            <Divider flexItem orientation="horizontal" />
            <Button
              aria-label="expand row button"
              fullWidth
              variant="text"
              sx={{ alignItems: 'center', lineHeight: 0 }}
              onClick={() => setOpen(!open)}
            >
              Ver {open ? 'menos' : 'más'}
              <MaterialIcon
                icon="keyboard_arrow_down"
                color="primary"
                sx={{
                  transform: open ? 'rotate(-180deg)' : 'rotate(0)',
                  transition: '0.2s ease',
                  lineHeight: 1,
                }}
              />
            </Button>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow
        sx={{
          display: { xs: 'none', md: 'table-row' },
          border: 'none',
        }}
      >
        <TableCell
          sx={{
            py: 0,
            px: { xs: 2, md: 0 },
            borderBottom: { xs: 'none', md: open ? '1px solid rgba(224, 224, 224, 1)' : 'none' },
          }}
          colSpan={12}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid
              sx={{
                p: 2,
                // mb: { xs: 2, md: 0 },
                // mt: { xs: -3, md: 0 },
                // borderRadius: { xs: 2, md: 0 },
                // borderTopLeftRadius: { xs: 2, md: 0 },
                // borderTopRightRadius: { xs: 2, md: 0 },
                // border: '1px solid #E9DCFF',
                borderTop: 'none',
                backgroundColor: COLORS.background[0],
              }}
            >
              {dropdownContent}
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export * from './filter';
