import { Button, IconButton, Tooltip } from '@mui/material';
import { PDFDownloadResponse } from '@repo/ecommerce-api';
import { FC } from 'react';
import { MaterialIcon } from '../materialIcon';

interface DownloadFileProps {
  service: (id?: number) => Promise<any>;
  onStart?: () => void;
  onSuccess?: (response: string | PDFDownloadResponse) => void;
  onError?: () => void;
  tooltip?: string;
  variant?: string;
  buttonText?: string;
}

export const DownloadFile: FC<DownloadFileProps> = ({
  service,
  onSuccess,
  onError,
  tooltip,
  variant = 'icon',
  buttonText,
}) => {
  const handleDownload = async () => {
    try {
      const response = await service();
      if (!response || response.softError || response.hardError) {
        onError?.();
      } else {
        onSuccess?.(response);
      }
    } catch (error) {
      onError?.();
    }
  };

  return (
    <>
      {variant === 'icon' ? (
        <Tooltip title={tooltip || 'Descargar CSV'}>
          <IconButton onClick={() => handleDownload()} sx={{ mt: -2, mb: -2, mr: -1 }}>
            <MaterialIcon data-testid="download-button" icon={'download'} color="primary" />
          </IconButton>
        </Tooltip>
      ) : (
        <Button onClick={() => handleDownload()}>{buttonText}</Button>
      )}
    </>
  );
};
