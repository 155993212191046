import { Box, Divider, Link, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DataFiscal, Logo } from '../../assets';
import { COLORS } from '../../constants';
import { Transition } from '../transition';

/**
 * Renders a reusable footer component that is displayed across every app.
 *
 * @return {JSX.Element} The rendered footer component.
 */
export const Footer = () => (
  <Box sx={{ overflow: 'hidden' }} data-testid="footer">
    <Transition type="fadeUp">
      <Grid
        component="footer"
        sx={{
          display: { xs: 'none', md: 'flex' },
          flexDirection: 'column',
          gap: 6,
          width: '100vw',
          backgroundColor: 'background.paper',
          px: 8,
          py: 4,
        }}
      >
        <Stack direction="column" gap={2}>
          <Grid>
            <Logo type="small" height={48} />
          </Grid>
          <Stack direction="row" gap={4} sx={{ color: 'primary.main' }}>
            {/* @todo: Links will be props */}
            <Link variant="body2" href="https://app.directo.com.ar/contacto" target="_blank" rel="noreferrer">
              Contacto
            </Link>
            <Link
              variant="body2"
              href="https://app.directo.com.ar/preguntas-frecuentes"
              target="_blank"
              rel="noreferrer"
            >
              Preguntas frecuentes
            </Link>
            <Link
              variant="body2"
              href="https://app.directo.com.ar/politica-privacidad"
              target="_blank"
              rel="noreferrer"
            >
              Políticas de privacidad
            </Link>
            <Link
              variant="body2"
              href="https://app.directo.com.ar/terminos-condiciones"
              target="_blank"
              rel="noreferrer"
            >
              Términos y condiciones
            </Link>
          </Stack>
        </Stack>
        <Divider />
        <Stack direction="row" gap={4} justifyContent="flex-end" alignItems="center">
          <Stack sx={{ textAlign: 'right', justifyContent: 'center', color: COLORS.text.light2 }}>
            <Typography fontFamily="sans-serif" variant="caption">
              Crédito Directo S.A. – CUIT N° 30-71210113-6
            </Typography>
            <Typography fontFamily="sans-serif" variant="caption">
              Av. Corrientes 1174, Piso 7°
            </Typography>
            <Typography fontFamily="sans-serif" variant="caption">
              Ciudad Autónoma de Buenos Aires
            </Typography>
          </Stack>
          <DataFiscal />
        </Stack>
      </Grid>
    </Transition>
  </Box>
);
